html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-y: hidden; /* Hide vertical scrollbar */
  overflow-x: hidden; /* Hide horizontal scrollbar */
}
iframe{
  width: 100%;
  height: 100%;
  border: none;
  margin: 0;
  padding: 0;
  overflow-y: hidden; /* Hide vertical scrollbar */
  overflow-x: hidden; /* Hide horizontal scrollbar */
}